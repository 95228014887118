import { CustomEventWithDetail, html, View } from 'rune-ts';
import klass from './AutoCompleteSearchInput.module.scss';
import { typo } from '../../../../../shared/typography/typo';
import { htmlIf } from '../../../../../shared/util';
import { pipe, join, map, find } from '@fxts/core';
import { AutoCompleteListRetrieveResponse } from '../../../../../features/Search/type/autocomplete';

export class AutoCompleteClickEvent extends CustomEventWithDetail<string> {}

export interface AutoCompleteListData extends AutoCompleteListRetrieveResponse {
  value: string;
}

type AutoCompleteListKlass = {
  list: string;
  item: string;
};

export type AutoCompleteListOption = {
  /**
   * 자동완성 목록에서 `value`와 일치하는 문자를 어떻게 강조할 것인지
   * - `fill` 배경색을 변경하여 강조
   * - `stroke` 텍스트의 색상을 변경하여 강조
   */
  highlight: 'fill' | 'stroke';
};

export class AutoCompleteList extends View<AutoCompleteListData> {
  private readonly klass: AutoCompleteListKlass;
  private readonly option: AutoCompleteListOption;

  constructor(
    readonly props: {
      data?: AutoCompleteListData;
      klass?: Partial<AutoCompleteListKlass>;
      option: AutoCompleteListOption;
    },
  ) {
    super(
      props.data ?? {
        auto_completes: [],
        value: '',
      },
    );
    this.klass = {
      list: props.klass?.list ?? '',
      item: props.klass?.item ?? '',
    };
    this.option = props.option;
  }

  override template() {
    const name_pattern = this.getPatternWithSpaces();
    return html`
      <ul
        class="${klass.auto_complete_container} ${typo('14_medium')} ${htmlIf(
          klass.hidden,
          this.isEmpty(),
        )} ${this.klass.list}"
      >
        ${this.data.auto_completes.map(({ name }) => {
          const matched = name_pattern.exec(name);
          if (!matched) {
            return '';
          }
          const [matched_name] = matched;
          const start_idx = matched.index;
          const end_idx = start_idx + matched_name.length;

          return html`<div data-search="${name}" class="${klass.auto_complete_anchor} ${this.klass.item}">
            <li class="${klass.auto_complete} ${typo('14_medium')}">
              <span>${html.preventEscape(name.slice(0, start_idx))}</span
              ><span
                class="${klass.highlight} ${this.option.highlight === 'fill' ? klass.fill : klass.stroke}"
                >${html.preventEscape(matched_name)}</span
              ><span>${html.preventEscape(name.slice(end_idx))}</span>
            </li>
          </div>`;
        })}
      </ul>
    `;
  }

  override onRender() {
    this.delegate('mouseover', `.${klass.auto_complete_anchor}`, (e) => {
      const el = e.currentTarget as HTMLElement;
      const els = this.element().querySelectorAll(`.${klass.auto_complete_anchor}`);
      els.forEach((el) => el.classList.remove(klass.focused));
      el.classList.add(klass.focused);
    });

    this.delegate('click', `.${klass.auto_complete_anchor}`, (e) => {
      const el = e.currentTarget as HTMLElement;
      const search = el.dataset.search;
      if (search) {
        this.dispatchEvent(AutoCompleteClickEvent, { bubbles: true, detail: search });
      }
    });
  }

  show() {
    this.element().classList.remove(klass.hidden);
  }

  hide() {
    this.element().classList.add(klass.hidden);
  }

  setData(data: AutoCompleteListData) {
    this.data.auto_completes = data.auto_completes;
    this.data.value = data.value;
  }

  moveFocusUp() {
    const els = this.element().querySelectorAll(`.${klass.auto_complete_anchor}`);
    if (!els.length) {
      return;
    }
    const last_el = els[els.length - 1];
    const focused_el = find((el) => el.classList.contains(klass.focused), els);
    if (!focused_el) {
      if (last_el) {
        last_el.classList.add(klass.focused);
      }
      return;
    }

    focused_el.classList.remove(klass.focused);
    if (focused_el.previousElementSibling) {
      focused_el.previousElementSibling.classList.add(klass.focused);
    } else {
      if (last_el) {
        last_el.classList.add(klass.focused);
      }
    }
  }

  moveFocusDown() {
    const els = this.element().querySelectorAll(`.${klass.auto_complete_anchor}`);
    if (!els.length) {
      return;
    }
    const first_el = els[0];
    const focused_el = find((el) => el.classList.contains(klass.focused), els);
    if (!focused_el) {
      if (first_el) {
        first_el.classList.add(klass.focused);
      }
      return;
    }

    focused_el.classList.remove(klass.focused);
    if (focused_el.nextElementSibling) {
      focused_el.nextElementSibling.classList.add(klass.focused);
    } else {
      if (first_el) {
        first_el.classList.add(klass.focused);
      }
    }
  }

  getFocusedEl() {
    return this.element().querySelector<HTMLAnchorElement>(`.${klass.auto_complete_anchor}.${klass.focused}`);
  }

  private isEmpty() {
    return !this.data.auto_completes.length;
  }

  private escapeRegExp(str: string) {
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  private getPatternWithSpaces(): RegExp {
    const input = (this.data.value || '').replace(' ', '');
    if (input.length <= 1) {
      return new RegExp(input);
    }
    const WHITESPACE_ZERO_OR_ONE = '\\s?';

    return pipe(
      input,
      map((str) => this.escapeRegExp(str) + WHITESPACE_ZERO_OR_ONE),
      join(''),
      (str) => str.replace(/\\s\?$/i, ''),
      (str) => new RegExp(str, 'i'),
    );
  }
}
